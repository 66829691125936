@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;
@layer base {
  img {
    @apply inline-block;
  }
}
@import 'katex/dist/katex.min.css';

@supports(padding:max(0px)) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

::-webkit-scrollbar-track{
  @apply bg-transparent rounded ;
}
::-webkit-scrollbar{
  @apply bg-pap-50  w-2 rounded-full;
}
::-webkit-scrollbar-thumb{
  @apply bg-pap-400 rounded-full;
}

:root {
  accent-color: #3c3f44;
}
